import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import axios from 'axios';
import router from '@/router/routers';
import { Notification } from 'element-ui';
import store from "../store";
import { getToken } from '@/utils/auth';
import { Encrypt, Decrypt } from '@/utils/aesEncrypt';
import Config from '@/settings';
import Cookies from 'js-cookie';
import secureUrl from '@/api/secureUrl';
import { readBlobAsText } from '@/utils/index'; // 创建axios实例

var service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/',
  // api 的 base_url
  timeout: Config.timeout // 请求超时时间

}); // request拦截器

service.interceptors.request.use(function (config) {
  if (getToken()) {
    config.headers['Authorization'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  config.headers['Content-Type'] = 'application/json';
  secureUrl.forEach(function (item) {
    if (item.url === config.url) {
      if (item.method === 'get') {
        config.params = {
          payload: Encrypt(JSON.stringify(config.params))
        };
      } else {
        config.data = {
          payload: Encrypt(JSON.stringify(config.data))
        };
      }
    }
  });
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug

  Promise.reject(error);
}); // response 拦截器

service.interceptors.response.use(function (response) {
  var code = response.status;

  if (code < 200 || code > 300) {
    Notification.error({
      title: response.message
    });
    return Promise.reject('error');
  }

  if (response.data.payload) {
    response.data = JSON.parse(Decrypt(response.data.payload));
  }

  return response.data;
}, function (error) {
  var code = 0;
  var request = error.request,
      response = error.response;
  console.log(request, response);

  try {
    if (response.data.payload) {
      response.data = JSON.parse(Decrypt(response.data.payload));
    }
  } catch (e) {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      Notification.error({
        title: '网络请求超时',
        duration: 5000
      });
      return Promise.reject(error);
    }
  }

  code = response.data.status;

  if (code) {
    // 防止循环刷页面
    if (router.currentRoute.path !== '/login' && code === 401) {
      store.dispatch('LogOut').then(function () {
        // 用户登录界面提示
        Cookies.set('point', 401);
        location.reload();
      });
    } else if (code === 403) {
      router.push({
        path: '/401'
      });
    } else {
      var errorMsg = response.data.message;

      if (errorMsg !== undefined && errorMsg !== '') {
        Notification.error({
          title: errorMsg,
          duration: 5000
        });
      }
    }
  } else if (request.responseType === 'blob' && response.headers['content-type'].includes('json')) {
    return readBlobAsText(response.data);
  } else {
    Notification.error({
      title: '接口请求失败',
      duration: 5000
    });
  }

  return Promise.reject(error);
});
export default service;