var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": _vm.$store.state.settings.uniqueOpened,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            _vm._l(_vm.permission_routers, function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }