import "core-js/modules/es6.regexp.to-string";
import CryptoJS from 'crypto-js';
var secret = 'TAQHv5iYf2bG5ZoioXVF1U5pHmCZb8Cl'; // 解密方法

export function Decrypt(word) {
  if (!word) {
    return;
  }

  var encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  var str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  var decrypt = CryptoJS.AES.decrypt(str, CryptoJS.enc.Utf8.parse(secret.substring(16)), {
    iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypt);
} // 加密方法

export function Encrypt(word) {
  if (!word) {
    return;
  }

  var str = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(str, CryptoJS.enc.Utf8.parse(secret.substring(16)), {
    iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.ciphertext.toString().toUpperCase();
}