import "E:\\yiluyouli\\yiluyouli-frontend\\node_modules\\core-js\\modules\\es6.array.iterator.js";
import "E:\\yiluyouli\\yiluyouli-frontend\\node_modules\\core-js\\modules\\es6.promise.js";
import "E:\\yiluyouli\\yiluyouli-frontend\\node_modules\\core-js\\modules\\es6.object.assign.js";
import "E:\\yiluyouli\\yiluyouli-frontend\\node_modules\\core-js\\modules\\es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import Element from 'element-ui'; // 数据字典

import dict from "./components/Dict"; // 权限指令

import permission from "./components/Permission";
import "./assets/styles/element-variables.scss"; // global css

import "./assets/styles/index.scss";
import App from "./App";
import store from "./store";
import router from "./router/routers";
import "./assets/icons"; // icon

import "./router/index"; // permission control

import ElTreeSelect from 'el-tree-select';
Vue.use(ElTreeSelect);
Vue.use(permission);
Vue.use(dict);
Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size

});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});