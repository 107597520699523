import { constantRouterMap } from '@/router/routers';
import Layout from '@/layout/index';
var permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, asyncRouter) {
      var commit = _ref.commit;
      commit('SET_ROUTERS', asyncRouter);
    }
  }
};
export var filterAsyncRouter = function filterAsyncRouter(routers) {
  // 遍历后台传来的路由字符串，转换为组件对象
  return routers.filter(function (router) {
    if (router.component) {
      if (router.component === 'Layout') {
        // Layout组件特殊处理
        router.component = Layout;
      } else {
        var component = router.component;
        router.component = loadView(component);
      }
    }

    if (router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children);
    }

    return true;
  });
};
export var loadView = function loadView(view) {
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
export default permission;